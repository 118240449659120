.term-table {
  tr {
    td,
    th {
      position: relative;
      .highlighted-yellow {
        background-color: yellow;
      }

      .highlighted-red {
        background-color: rgb(252, 138, 154);
      }

      .highlighted-green {
        background-color: rgb(120, 221, 159);
      }

      .highlighted-blue {
        background-color: rgb(116, 227, 241);
      }

      .special-column {
        background-color: rgba(248, 252, 255, 0.996) !important;
      }
    }
    .line-actions {
      position: absolute;
      right: -3px;
      bottom: -44px;
      height: 44px;
      padding: 7px;
      z-index: 100;
      white-space: nowrap;
    }
    th[lr-drag-src] {
      cursor: move;
    }

    .entry-action-icons {
      opacity: 0;

      position: relative;
      // display: block;
      // height: 51px;
      // line-height: 50px;
      cursor: default;
      transition-duration: 0.3s;

      ul {
        display: flex;
        position: absolute;
        transition: all 0.5s;
        right: -180px;

        li {
          margin: 0 10px;
        }

        .entry-action-icons-item {
          font-size: 20px;
          transition: all 0.5s;

          &:hover {
            color: $danger;
          }
        }
      }
    }

    // &:hover {
    //   background: #{map-get($grays, "100")};
    //   transition-duration: 0.05s;

    //   .entry-action-icons {
    //     opacity: 1;

    //     ul {
    //       transition: all 0.5s;
    //       right: 10px;
    //     }
    //   }
    // }
  }

  tbody > tr.new-line {
    border: 3px solid rgb(66, 139, 202);
    background-color: #a0c5e4;
    transition: all 1s ease;
    .line-actions {
      background-color: rgb(66, 139, 202);
    }
    &.added {
      position: absolute;
      width: 100%;
      z-index: 10000;
      transform: translateY(300px) scale(0.98);
      opacity: 0;
      .line-actions {
        display: none;
      }
    }
  }

  tbody > tr.new-line > td {
    background-color: #a0c5e4;
  }

  // NOTE tbody > tr is needed
  // because bootstrap table-stripe adds background-color inside
  // a more qualified rule than only ".edit-in-progress' rule

  tbody > tr.edit-in-progress {
    border: 3px solid rgb(248, 231, 28);
    background-color: rgba(248, 231, 28, 0.5);
    .line-actions {
      background-color: rgb(248, 231, 28);
    }
    .input-group {
      width: 100%;
    }
  }

  tbody > tr.edit-in-progress > td {
    background-color: rgb(248, 231, 28);
  }

  tbody > tr.public-entry {
    // background-color: rgba(242, 252, 248, 0.631);
    background-color: rgba(222, 252, 232, 0.631);
  }

  tbody > tr > td.special-column {
    // background-color: rgba(248, 252, 255, 0.996) !important;
    background-color: rgba(255, 243, 230, 0.996) !important;
  }
}
