$public_glossary_color: #78a600;
$shared_glossary_color: #d6a800;
$private_glossary_color: #004479;

div.btn-cancel-custom {
  background-color: rgb(192, 192, 192);
}

// TODO
// - Handle light/dark theme
.glossary-private,
.glossary-private a {
  color: $private_glossary_color;
}

.glossary-shared,
.glossary-shared a {
  color: $shared_glossary_color;
}

.glossary-public,
.glossary-public a {
  color: $public_glossary_color;
}

.label-public {
  background-color: $public_glossary_color;
}
.label-public:hover {
  background-color: $public_glossary_color;
}

.label-private {
  background-color: $private_glossary_color;
}
.label-private:hover {
  background-color: $private_glossary_color;
}

.badge-private {
  background-color: $private_glossary_color !important;
}

.badge-public {
  background-color: $public_glossary_color !important;
}

.badge-shared {
  background-color: $shared_glossary_color !important;
}

.entry-img-thumb {
  cursor: zoom-in;
}
