.moveable {
  cursor: move;
}

.clickable {
  cursor: pointer;
}

.special-column {
  background-color: rgba(248, 252, 255, 0.996) !important;
}
